
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import useTranslation from 'next-translate/useTranslation';
import classes from './index.module.scss';
import CheckIcon from '@/images/common/check.svg';
import { BenefitsConfig } from '@/types/Config/Benefits';
import { P } from '@/components/common/Typography';
const Benefits: React.FunctionComponent<{
    data: BenefitsConfig;
}> = ({ data, }) => {
    const { t } = useTranslation('home');
    return (<div className={classes.layout}>
      {data.map((benefit, i) => (<div className={classes.benefit} key={i}>
          <div className={classes.iconWrapper}>
            <img className={classes.image} src={CheckIcon.src} alt="" width={CheckIcon.width} height={CheckIcon.height}/>
          </div>
          <P className={classes.paragraph} dangerouslySetInnerHTML={{ __html: t(benefit) }}/>
        </div>))}
    </div>);
};
export default Benefits;

    async function __Next_Translate__getStaticProps__1910ce3e303__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//Home/components/TryOutForFree/components/Benefits/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1910ce3e303__ as getStaticProps }
  